import React from 'react'
import isBrowser from 'shared/is-browser'
import qs from 'query-string'

export const countryCodes = {
	au: 'AU',
	nz: 'NZ',
	us: 'US',
	uk: 'UK',
	ie: 'IE',
}

export const currencySymbols = {
	[countryCodes.au]: '$',
	[countryCodes.nz]: '$',
	[countryCodes.us]: '$',
	[countryCodes.uk]: '£',
	[countryCodes.ie]: '€',
}

export const contractorTypeByLocale = country => {
	switch (country) {
		case countryCodes.au:
		case countryCodes.nz:
			return 'Head Contractors'
		case countryCodes.ie:
		case countryCodes.uk:
			return 'Main Contractors'
		default:
			return 'General Contractors'
	}
}

export const getCountry = () => {
	if (!isBrowser) {
		return null
	}

	// consider removing this in the future, or making this a real feature
	const countryOverride = qs.parse(window.location.search).locale
	if (countryOverride && !!countryCodes[`${countryOverride}`])
		return countryCodes[`${countryOverride}`]

	const href = window.location.href
	if (href.includes('.co.nz')) {
		return countryCodes.nz
	}
	if (href.includes('.com.au')) {
		return countryCodes.au
	}
	if (href.includes('.co.uk')) {
		return countryCodes.uk
	}
	if (href.includes('.ie')) {
		return countryCodes.ie
	}
	return countryCodes.us
}

export const isUSA = country => country === 'US'

export default Component => {
	return class WithCountry extends React.Component {
		state = {
			country: getCountry(),
		}

		render() {
			return <Component country={this.state.country} {...this.props} />
		}
	}
}
