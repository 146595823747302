import React from 'react'
import { A, Div } from 'glamorous'

import CenteredContent from '../centered-content'
import Spacer from '../spacer'
import { lightGray } from '../../shared/colors'
import { smallerThan } from '../../shared/media-queries'
import FbIcon from '../../imgs/social/Facebook.svg'
import TwitterIcon from '../../imgs/social/Twitter.svg'
import LinkedInIcon from '../../imgs/social/LinkedIn.svg'
import InstagramIcon from '../../imgs/social/Insta.svg'

import withScreenWidth from '../../shared/with-screen-width'
import { getScreenBreakpoint } from '../../shared/constants'
import generateContent from './footer-content'
import withCountry from 'shared/get-country'

const Footer = ({ screenWidth, country }) => {
	const content = generateContent(country)
	const { supportEmail, supportEmailCopy } = content
	if (!country) return null
	const xsmall = screenWidth < getScreenBreakpoint('x-small')
	return (
		<Div background="#FAFAFA">
			<CenteredContent>
				<Spacer height={35} />
				<Div display="flex" justifyContent="space-between" fontSize="14px">
					<Div color={lightGray}>
						© BuildingConnected {new Date().getFullYear()}
					</Div>

					<Div display="flex">
						<A
							href={supportEmail}
							color={lightGray}
							css={{ [smallerThan('xx-small')]: { display: 'none' } }}
						>
							{supportEmailCopy}
						</A>
						<Spacer width={xsmall ? 20 : 73} />
						<Div display="flex" width="150px" justifyContent="space-between">
							<a href="https://www.facebook.com/BuildingConnectedcom-195490413862378">
								<FbIcon width="24px" height="24px" />
							</a>
							<a href="https://www.buildingconnected.com/">
								<LinkedInIcon width="24px" height="24px" />
							</a>
							<a href="https://twitter.com/BldgConnected">
								<TwitterIcon width="24px" height="24px" />
							</a>
							<a href="http://instagram.com/buildingconnected">
								<InstagramIcon width="24px" height="24px" />
							</a>
						</Div>
					</Div>
				</Div>
				<Spacer height={30} />
			</CenteredContent>
		</Div>
	)
}

export default withCountry(withScreenWidth(Footer))
