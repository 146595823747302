// @flow
import { useState } from 'react'

import { getCountry } from 'shared/get-country'

const useCountry = () => {
	const [country] = useState(getCountry())
	return country
}

export default useCountry
