import { contractorTypeByLocale } from 'shared/get-country'

export const USLinks = {
	mainLinks: {
		bcpro: {
			title: 'BuildingConnected Pro',
			url: '/bc-pro/',
			subtitle:
				'Leverage the largest network in N.A. to invite the right vendors, manage proposals, and award the best bids.',
		},
		bbpro: {
			title: 'Bid Board Pro',
			url: '/bid-board/',
			subtitle:
				'Manage all your bid invites with our online bid board so you can win the right jobs.',
		},
		tradetapp: {
			title: 'TradeTapp',
			url: '/tradetapp/',
			subtitle:
				'Qualify subs through automated analysis and recommendations so you can mitigate risks sooner.',
		},
		movebbo: {
			title: 'Move Your Bid Board Online',
			url: '/bid-board/move-bid-board-online/',
			subtitle:
				'Save time, eliminate errors, and track all bids from one place.',
		},
		winMore: {
			title: 'Win More with Bid Board Pro',
			url: '/bid-board/',
			subtitle: 'Check out the full set of features.',
		},
		getStarted: {
			title: 'Get Started with our Bidding Toolkit',
			url: '/bid-board/toolkit/',
			subtitle: 'Learn how to bid through BuildingConnected.',
		},
		blog: {
			title: 'Blog',
			url: 'https://constructionblog.autodesk.com/',
			subtitle:
				'Get the latest in construction news, industry insights and cool projects.',
		},
		helpCenter: {
			title: `Help Center`,
			url: 'https://buildingconnected.zendesk.com/hc/en-us',
			subtitle: 'Access support articles or submit a request for help.',
		},
		customerStories: {
			title: 'Customer Stories',
			url: 'https://construction.autodesk.com/customers',
			subtitle: 'See how BuildingConnected has helped GCs, Subs and Owners.',
		},
		promoVideos: {
			title: 'Videos',
			url: '/videos/',
			subtitle:
				'Watch promotional videos highlighting key products and features.',
		},
		useNetwork: {
			title: 'Get discovered by GCs on the network',
			url: '/bid-board/bid-invites/',
			subtitle:
				'Receive the right ITBs on the biggest network for construction.',
		},
		tenders: {
			title: 'Tenders',
			url: '/bid-board/bid-invites/',
			subtitle:
				'Leverage the largest network in N.A. to invite the right vendors, manage proposals, and award the best tenders',
		},
		subNetwork: {
			title: 'Subcontractor Network',
			url: '/customers/',
			subtitle: 'Win more work with a real-time construction network.',
		},
		tenderComparison: {
			title: 'Tender Comparison',
			url: '/bid-leveling/',
			subtitle:
				'Identify the best tender accurately and efficiently, from one place.',
		},
		getStartedTendering: {
			title: 'Get started with our tendering toolkit',
			url: '/bid-leveling/',
			subtitle: 'Learn how to tender through BuildingConnected.',
		},
		subNetworkOther: {
			title: 'Subcontractor Network',
			url: '/subcontractor-construction-network/',
			subtitle: 'Win more work with a real-time construction network.',
		},
	},
	subLinks: {
		blog3: {
			title:
				'3 reasons general contractors should qualify their subcontractors',
			url:
				'https://blog.buildingconnected.com/3-reasons-general-contractors-should-prequalify-their-subcontractors/',
		},
		blog4: {
			title: '4 ways estimators can better manage their daily workload',
			url:
				'https://blog.buildingconnected.com/4-ways-estimators-can-better-manage-their-daily-workload/',
		},
		blog5: {
			title:
				'5 ways subcontractors can improve communication during the preconstruction process',
			url:
				'https://blog.buildingconnected.com/best-practices-to-improve-communication-during-preconstruction/',
		},
		bidTracking: {
			title: 'Automated Bid Tracking',
			url: '/bid-board/manage-bids/',
		},
		bcTradetapp: {
			title: 'BuildingConnected Pro + TradeTapp Integration',
			url: '/bc-tradetapp/',
		},
		bidAnalyticsITB: {
			title: `Bid Analytics`,
			url: '/bid-board/bid-analytics',
		},
		bidAnalyticsBC: {
			title: `Bid Analytics`,
			url: '/bc-pro/?scrollTo=analytics',
		},
		bidCalendar: {
			title: 'Bid Calendar',
			url: '/bid-board/calendar/',
		},
		bidLeveling: {
			title: `Bid Leveling`,
			url: '/bid-leveling/',
		},
		bidInvite: {
			title: 'Invitations to bid',
			url: '/bc-pro/?scrollTo=management',
		},
		plansPricing: {
			title: 'Plans & Pricing',
			url: '/bid-board/pricing/',
		},
		bcproPlansPricing: {
			title: 'Plans & Pricing',
			url: '/bc-pro/pricing/',
		},
		bcproROICalc: {
			title: 'ROI Calculator',
			url: '/bc-pro/pricing/?scrollTo=roi',
		},
		riskAnalysis: {
			title: 'Risk Analysis',
			url: '/tradetapp/?scrollTo=analysis',
		},
		riskMitigation: {
			title: 'Risk Mitigation',
			url: '/tradetapp/?scrollTo=mitigation',
		},
		riskMitigationTT: {
			title: 'Risk Mitigation with TradeTapp',
			url: '/bc-tradetapp/',
		},
		subsNetwork: {
			title: 'Subcontractor Network',
			url: '/subcontractor-construction-network',
		},
		tradetapp: {
			title: 'TradeTapp',
			url: '/tradetapp/',
		},
		vendorManagement: {
			title: 'Vendor Management',
			url: '/tradetapp/?scrollTo=management',
		},
		bbWistiaVideos: {
			title: 'Resource Hub',
			url: '/bid-board/resource-hub',
		},
		bcproWistiaVideos: {
			title: 'Resource Hub',
			url: '/bc-pro/resource-hub',
		},
		tradetappWistiaVideos: {
			title: 'Resource Hub',
			url: '/tradetapp/resource-hub',
		},
		tradetappRoiCalc: {
			title: 'ROI Calculator',
			url: '/tradetapp/roi-calculator/?scrollTo=roi',
		},
		takeoffs: {
			title: 'Takeoffs',
			url: '/bid-board/takeoffs',
		},
		tenderingToolkit: {
			title: 'Tendering Toolkit',
			url: '/bid-board/toolkit',
		},
		tenderAnalytics: {
			title: 'Tender Analytics',
			url: '/bc-pro/?scrollTo=analytics',
		},
	},
}

export const otherLinks = country => {
	return {
		mainLinks: {
			bcpro: {
				title: 'BuildingConnected Pro',
				url: '/bc-pro/',
				subtitle:
					'Leverage the largest network to invite the right vendors, manage proposals, and award the best tenders',
			},
			bbpro: {
				title: 'Bid Board Pro',
				url: '/bid-board/',
				subtitle:
					'Manage all your bid invites with our online bid board so you can win the right jobs.',
			},
			tradetapp: {
				title: 'TradeTapp',
				url: '/tradetapp/',
				subtitle:
					'Qualify subs through automated analysis and recommendations so you can mitigate risks sooner.',
			},
			movebbo: {
				title: 'Move Your Bid Board Online',
				url: '/bid-board/move-bid-board-online/',
				subtitle:
					'Save time, eliminate errors, and track all bids from one place.',
			},
			winMore: {
				title: 'Win More with Bid Board Pro',
				url: '/bid-board/',
				subtitle: 'Check out the full set of features.',
			},
			getStarted: {
				title: 'Get Started with our Bidding Toolkit',
				url: '/bid-board/toolkit/',
				subtitle: 'Learn how to bid through BuildingConnected.',
			},
			blog: {
				title: 'Blog',
				url: 'https://constructionblog.autodesk.com/',
				subtitle:
					'Get the latest in construction news, industry insights and cool projects.',
			},
			helpCenter: {
				title: `Help Centre`,
				url: 'https://buildingconnected.zendesk.com/hc/en-nz',
				subtitle: 'Access support articles or submit a request for help.',
			},
			customerStories: {
				title: 'Customer Stories',
				url: 'https://construction.autodesk.com/customers',
				subtitle: `See how BuildingConnected has helped ${contractorTypeByLocale(
					country
				)} and subs`,
			},
			promoVideos: {
				title: 'Videos',
				url: '/videos/',
				subtitle:
					'Watch promotional videos highlighting key products and features.',
			},
			useNetwork: {
				title: `Get discovered by ${contractorTypeByLocale(
					country
				)} on the network`,
				url: '/bid-board/bid-invites/',
				subtitle:
					'Receive the right ITBs on the biggest network for construction.',
			},
			tenders: {
				title: 'Tenders',
				url: '/bid-board/bid-invites/',
				subtitle:
					'Leverage the largest network to invite the right vendors, manage proposals, and award the best tenders',
			},
			subNetwork: {
				title: 'Subcontractor Network',
				url: '/customers/',
				subtitle: 'Win more work with a real-time construction network.',
			},
			tenderComparison: {
				title: 'Tender Comparison',
				url: '/bid-leveling/',
				subtitle:
					'Identify the best tender accurately and efficiently, from one place.',
			},
			getStartedTendering: {
				title: 'Get started with our Tendering Toolkit',
				url: '/bid-board/toolkit',
				subtitle: 'Learn how to tender through BuildingConnected.',
			},
			getDiscoveredHead: {
				title: `Get discovered by ${contractorTypeByLocale(
					country
				)} on the network`,
				url: '/bid-board/bid-invites',
				subtitle:
					'Receive the right invitations to tender on the biggest network for construction.',
			},
			subNetworkOther: {
				title: 'Subcontractor Network',
				url: '/subcontractor-construction-network/',
				subtitle: 'Win more work with a real-time construction network.',
			},
		},
		subLinks: {
			blog3: {
				title:
					'3 reasons general contractors should qualify their subcontractors',
				url:
					'https://blog.buildingconnected.com/3-reasons-general-contractors-should-prequalify-their-subcontractors/',
			},
			blog4: {
				title: '4 ways estimators can better manage their daily workload',
				url:
					'https://blog.buildingconnected.com/4-ways-estimators-can-better-manage-their-daily-workload/',
			},
			blog5: {
				title:
					'5 ways subcontractors can improve communication during the preconstruction process',
				url:
					'https://blog.buildingconnected.com/best-practices-to-improve-communication-during-preconstruction/',
			},
			bidTracking: {
				title: 'Automated Bid Tracking',
				url: '/bid-board/manage-bids/',
			},
			bcTradetapp: {
				title: 'BuildingConnected Pro + TradeTapp Integration',
				url: '/bc-tradetapp/',
			},
			bidAnalyticsITB: {
				title: `Tendering Analytics`,
				url: '/bid-board/bid-analytics',
			},
			bidAnalyticsBC: {
				title: `Tender Analytics`,
				url: '/bc-pro/?scrollTo=analytics',
			},
			bidCalendar: {
				title: 'Bid Calendar',
				url: '/bid-board/calendar/',
			},
			bidLeveling: {
				title: 'Tender Comparison',
				url: '/bid-leveling/',
			},
			bidInvite: {
				title: 'Invitations to Tender',
				url: '/bc-pro/?scrollTo=management',
			},
			plansPricing: {
				title: 'Plans & Pricing',
				url: '/bid-board/pricing/',
			},
			bcproPlansPricing: {
				title: 'Plans & Pricing',
				url: '/bc-pro/pricing/',
			},
			bcproROICalc: {
				title: 'ROI Calculator',
				url: '/bc-pro/pricing/?scrollTo=roi',
			},
			riskAnalysis: {
				title: 'Risk Analysis',
				url: '/tradetapp/?scrollTo=analysis',
			},
			riskMitigation: {
				title: 'Risk Mitigation',
				url: '/tradetapp/?scrollTo=mitigation',
			},
			riskMitigationTT: {
				title: 'Risk Mitigation with TradeTapp',
				url: '/bc-tradetapp/',
			},
			subsNetwork: {
				title: 'Subcontractor Network',
				url: '/subcontractor-construction-network',
			},
			tradetapp: {
				title: 'TradeTapp',
				url: '/tradetapp/',
			},
			vendorManagement: {
				title: 'Vendor Management',
				url: '/tradetapp/?scrollTo=management',
			},
			bbWistiaVideos: {
				title: 'Resource Hub',
				url: '/bid-board/resource-hub',
			},
			bcproWistiaVideos: {
				title: 'Resource Hub',
				url: '/bc-pro/resource-hub',
			},
			tradetappWistiaVideos: {
				title: 'Resource Hub',
				url: '/tradetapp/resource-hub',
			},
			takeoffs: {
				title: 'Takeoffs',
				url: '/bid-board/takeoffs',
			},
			tenderingToolkit: {
				title: 'Tendering Toolkit',
				url: '/bid-board/toolkit',
			},
			tenderAnalytics: {
				title: 'Tender Analytics',
				url: '/bc-pro/?scrollTo=analytics',
			},
		},
	}
}
