// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { showRequestDemoModal } from '../../../modal-manager'
import trackEvent from 'shared/track'

import { blue, gray20 } from 'shared/colors'
import { mobile } from 'shared/media-queries'
import { MobileHeaderLink, sharedDivCSS } from './mobile-header-link'

import { subMenuTitles } from './constants'
import { isUSA } from 'shared/get-country'

type RootMenuProps = {
	hideLogin: boolean,
	loginLink: string,
	updateMenuItem: string => mixed,
	setSubMenu: string => mixed,
	country: string,
}

const RootMenu = ({
	hideLogin,
	loginLink,
	updateMenuItem,
	setSubMenu,
	country,
}: RootMenuProps) => (
	<Div
		display="flex"
		flexDirection="column"
		overflow="scroll"
		css={{
			'& > *:not(:first-child)': {
				borderTop: `1px solid ${gray20}`,
			},
			'&:last-child': {
				borderBottom: `1px solid ${gray20}`,
			},
		}}
	>
		<MobileHeaderLink
			openMenu={() => {
				setSubMenu('products')
				updateMenuItem(subMenuTitles(country).products)
			}}
			title={subMenuTitles(country).products}
		/>
		<MobileHeaderLink to="/customers" title={subMenuTitles(country).network} />
		<MobileHeaderLink
			openMenu={() => {
				setSubMenu('gcs')
				updateMenuItem(subMenuTitles(country).gcs)
			}}
			title={subMenuTitles(country).gcs}
		/>
		<MobileHeaderLink
			openMenu={() => {
				setSubMenu('subs')
				updateMenuItem(subMenuTitles(country).subs)
			}}
			title={subMenuTitles(country).subs}
		/>
		{isUSA(country) && (
			<MobileHeaderLink to="/owners" title={subMenuTitles(country).owners} />
		)}
		<MobileHeaderLink
			openMenu={() => {
				setSubMenu('resources')
				updateMenuItem(subMenuTitles(country).resources)
			}}
			title={subMenuTitles(country).resources}
		/>
		{!hideLogin && (
			<MobileHeaderLink href={loginLink} title={subMenuTitles(country).login} />
		)}

		<Div
			css={sharedDivCSS}
			onClick={() => {
				trackEvent({
					category: 'Request Demo',
					action: 'Open modal',
					label: 'Nav bar',
				})
				showRequestDemoModal()
			}}
		>
			<Div
				color={blue}
				fontsize="18px"
				fontWeight="600"
				css={{ [mobile]: { fontSize: '14px' } }}
			>
				Get a Demo
			</Div>
		</Div>
	</Div>
)

export default RootMenu
