import { countryCodes } from 'shared/get-country'

const usCopy = {
	supportEmail: 'https://buildingconnected.zendesk.com/hc/en-us/requests/new',
	supportForm: 'https://buildingconnected.zendesk.com/hc/en-us/requests/new',
	supportEmailCopy: 'Need Help?',
	helpCenterUrl: 'http://support.buildingconnected.com',
	gcs: 'For GCs',
	subs: 'Subs',
	forSubsLink: '/bid-board',
}
const anzCopy = {
	supportEmail: 'mailto:acs.apac@autodesk.com',
	supportForm: 'https://buildingconnected.zendesk.com/hc/en-au/requests/new',
	supportEmailCopy: 'acs.apac@autodesk.com',
	helpCenterUrl: 'https://buildingconnected.zendesk.com/hc/en-au',
	gcs: `For Head Contractors`,
	subs: 'For Subcontractors',
	forSubsLink: '/bid-board/bid-invites',
}

const ieCopy = {
	supportEmail: 'mailto:acs.emea@autodesk.com',
	supportForm: 'https://buildingconnected.zendesk.com/hc/en-ie/requests/new',
	supportEmailCopy: 'acs.emea@autodesk.com',
	helpCenterUrl: 'https://buildingconnected.zendesk.com/hc/en-ie',
	gcs: `For Main Contractors`,
	subs: 'For Subcontractors',
	forSubsLink: '/bid-board/bid-invites',
}

const ukCopy = {
	supportEmail: 'mailto:acs.emea@autodesk.com',
	supportForm: 'https://buildingconnected.zendesk.com/hc/en-uk/requests/new',
	supportEmailCopy: 'acs.emea@autodesk.com',
	helpCenterUrl: 'https://buildingconnected.zendesk.com/hc/en-uk',
	gcs: `For Main Contractors`,
	subs: 'For Subcontractors',
	forSubsLink: '/bid-board/bid-invites',
}

const content = country => {
	switch (country) {
		case countryCodes.au:
		case countryCodes.nz:
			return anzCopy
		case countryCodes.ie:
			return ieCopy
		case countryCodes.uk:
			return ukCopy
		default:
			return usCopy
	}
}

export default content
