// @flow
import * as React from 'react'
import { Div } from 'glamorous'
import { ClickableWithSubtitle, SmallLink, ThinDivider } from '../components'
import { isUSA } from 'shared/get-country'
import { USLinks, otherLinks } from '../shared/links-with-copy'

const otherInfo = country => ({
	products: [
		{
			title: 'Tender Management',
			content: [
				<ClickableWithSubtitle {...otherLinks(country).mainLinks.bcpro} />,
				<SmallLink {...otherLinks(country).subLinks.subsNetwork} />,
				<SmallLink {...otherLinks(country).subLinks.bidAnalyticsBC} />,
				<SmallLink {...otherLinks(country).subLinks.bidInvite} />,
				<SmallLink {...otherLinks(country).subLinks.bidLeveling} />,
				<SmallLink {...USLinks.subLinks.bcproROICalc} />,
				<SmallLink {...otherLinks(country).subLinks.bcproWistiaVideos} />,
			],
		},
		{
			title: 'Opportunity Tracking',
			content: [
				<ClickableWithSubtitle {...otherLinks(country).mainLinks.tenders} />,
				<SmallLink {...otherLinks(country).subLinks.tenderingToolkit} />,
			],
		},
	],
	gcs: [
		{
			title: 'Tender Management',
			content: [
				<Div display="flex">
					<Div flex="0 0 65%">
						<ClickableWithSubtitle {...otherLinks(country).mainLinks.bcpro} />
						<Div display="flex" justifyContent="space-between" maxWidth="450px">
							<Div display="flex" flexDirection="column" maxWidth="195px">
								<SmallLink {...otherLinks(country).subLinks.subsNetwork} />
								<SmallLink {...otherLinks(country).subLinks.bidAnalyticsBC} />
								<SmallLink {...USLinks.subLinks.bcproROICalc} />
								<SmallLink
									{...otherLinks(country).subLinks.bcproWistiaVideos}
								/>
							</Div>
						</Div>
					</Div>
					<Div display="flex" flexDirection="column" flex="0 0 35%">
						<ClickableWithSubtitle
							{...otherLinks(country).mainLinks.subNetworkOther}
						/>
						<ClickableWithSubtitle
							{...otherLinks(country).mainLinks.tenderComparison}
						/>
					</Div>
				</Div>,
			],
		},
	],
	subs: [
		{
			title: 'Opportunity Tracking',
			flex: '1 1 0%',
			content: [
				<ClickableWithSubtitle
					{...otherLinks(country).mainLinks.getDiscoveredHead}
				/>,
			],
		},
		{
			title: 'Getting Started',
			flex: '1 1 0%',
			content: [
				<ClickableWithSubtitle
					{...otherLinks(country).mainLinks.getStartedTendering}
				/>,
			],
		},
	],
	resources: [
		{
			title: '',
			flex: '1 1 30%',
			content: [
				<Div display="flex" justifyContent="space-between">
					<ClickableWithSubtitle
						styles={{ maxWidth: '180px' }}
						{...otherLinks(country).mainLinks.helpCenter}
					/>
					<ThinDivider />
					<ClickableWithSubtitle
						styles={{ maxWidth: '207px' }}
						{...otherLinks(country).mainLinks.customerStories}
					/>
					<ThinDivider />
				</Div>,
			],
		},
		{
			title: '',
			flex: '1 1 5%',
			content: [
				<ClickableWithSubtitle {...otherLinks(country).mainLinks.blog} />,
				<SmallLink {...otherLinks(country).subLinks.blog4} />,
				<SmallLink {...otherLinks(country).subLinks.blog5} />,
				<SmallLink {...otherLinks(country).subLinks.blog3} />,
			],
		},
	],
})
const USInfo = {
	products: [
		{
			title: 'Bid Management',
			content: [
				<ClickableWithSubtitle {...USLinks.mainLinks.bcpro} />,
				<SmallLink {...USLinks.subLinks.subsNetwork} />,
				<SmallLink {...USLinks.subLinks.bidAnalyticsBC} />,
				<SmallLink {...USLinks.subLinks.riskMitigationTT} />,
				<SmallLink {...USLinks.subLinks.bidInvite} />,
				<SmallLink {...USLinks.subLinks.bidLeveling} />,
				<SmallLink {...USLinks.subLinks.bcproPlansPricing} />,
				<SmallLink {...USLinks.subLinks.bcproROICalc} />,
				<SmallLink {...USLinks.subLinks.bcproWistiaVideos} />,
			],
		},
		{
			title: 'Risk Management',
			content: [
				<ClickableWithSubtitle {...USLinks.mainLinks.tradetapp} />,
				<SmallLink {...USLinks.subLinks.riskAnalysis} />,
				<SmallLink {...USLinks.subLinks.riskMitigation} />,
				<SmallLink {...USLinks.subLinks.vendorManagement} />,
				<SmallLink {...USLinks.subLinks.bcTradetapp} />,
				<SmallLink {...USLinks.subLinks.tradetappWistiaVideos} />,
				<SmallLink {...USLinks.subLinks.tradetappRoiCalc} />,
			],
		},
		{
			title: 'Opportunity Tracking',
			content: [
				<ClickableWithSubtitle {...USLinks.mainLinks.bbpro} />,
				<SmallLink {...USLinks.subLinks.bidTracking} />,
				<SmallLink {...USLinks.subLinks.bidCalendar} />,
				<SmallLink {...USLinks.subLinks.bidAnalyticsITB} />,
				<SmallLink {...USLinks.subLinks.takeoffs} />,
				<SmallLink {...USLinks.subLinks.plansPricing} />,
				<SmallLink {...USLinks.subLinks.bbWistiaVideos} />,
			],
		},
	],
	gcs: [
		{
			title: 'Bid Management',
			content: [
				<ClickableWithSubtitle {...USLinks.mainLinks.bcpro} />,
				<Div display="flex" justifyContent="space-between" maxWidth="450px">
					<Div display="flex" flexDirection="column" maxWidth="195px">
						<SmallLink {...USLinks.subLinks.subsNetwork} />
						<SmallLink {...USLinks.subLinks.bidAnalyticsBC} />
						<SmallLink {...USLinks.subLinks.riskMitigationTT} />
					</Div>
					<Div display="flex" flexDirection="column" maxWidth="170px">
						<SmallLink {...USLinks.subLinks.bidInvite} />
						<SmallLink {...USLinks.subLinks.bidLeveling} />
						<SmallLink {...USLinks.subLinks.bcproWistiaVideos} />
					</Div>
					<Div display="flex" flexDirection="column" maxWidth="195px">
						<SmallLink {...USLinks.subLinks.bcproPlansPricing} />
						<SmallLink {...USLinks.subLinks.bcproROICalc} />
					</Div>
				</Div>,
			],
		},
		{
			title: 'Risk Management',
			content: [
				<ClickableWithSubtitle {...USLinks.mainLinks.tradetapp} />,
				<Div display="flex" justifyContent="space-between" maxWidth="375px">
					<Div display="flex" flexDirection="column" maxWidth="170px">
						<SmallLink {...USLinks.subLinks.riskAnalysis} />
						<SmallLink {...USLinks.subLinks.riskMitigation} />
						<SmallLink {...USLinks.subLinks.vendorManagement} />
						<SmallLink {...USLinks.subLinks.tradetappRoiCalc} />
					</Div>
					<Div display="flex" flexDirection="column" maxWidth="200px">
						<SmallLink {...USLinks.subLinks.bcTradetapp} />
						<SmallLink {...USLinks.subLinks.tradetappWistiaVideos} />
					</Div>
				</Div>,
			],
		},
	],
	subs: [
		{
			title: 'How to Win the Bids You Want',
			flex: '2 2 0%',
			content: [
				<Div display="flex" flexWrap="wrap" justifyContent="space-between">
					<Div flex="0 0 47%">
						<ClickableWithSubtitle {...USLinks.mainLinks.useNetwork} />
					</Div>
					<Div flex="0 0 47%">
						<ClickableWithSubtitle {...USLinks.mainLinks.movebbo} />
					</Div>
					<Div flex="0 0 47%">
						<ClickableWithSubtitle {...USLinks.mainLinks.getStarted} />
					</Div>
					<Div flex="0 0 47%">
						<ClickableWithSubtitle {...USLinks.mainLinks.winMore} />
					</Div>
				</Div>,
			],
		},
		{
			title: 'Opportunity Tracking',
			flex: '1 1 0%',
			content: [
				<ClickableWithSubtitle {...USLinks.mainLinks.bbpro} />,
				<Div display="flex" justifyContent="space-between" maxWidth="290px">
					<Div display="flex" flexDirection="column">
						<SmallLink {...USLinks.subLinks.bidTracking} />
						<SmallLink {...USLinks.subLinks.bidCalendar} />
						<SmallLink {...USLinks.subLinks.bbWistiaVideos} />
					</Div>
					<Div display="flex" flexDirection="column">
						<SmallLink {...USLinks.subLinks.bidAnalyticsITB} />
						<SmallLink {...USLinks.subLinks.takeoffs} />
						<SmallLink {...USLinks.subLinks.plansPricing} />
					</Div>
				</Div>,
			],
		},
	],
	resources: [
		{
			title: '',
			flex: '1 1 30%',
			content: [
				<Div display="flex" justifyContent="space-between">
					<ClickableWithSubtitle
						styles={{ maxWidth: '180px' }}
						{...USLinks.mainLinks.helpCenter}
					/>
					<ThinDivider />
					<ClickableWithSubtitle
						styles={{ maxWidth: '207px' }}
						{...USLinks.mainLinks.customerStories}
					/>
					<ThinDivider />
				</Div>,
			],
		},
		{
			title: '',
			flex: '1 1 5%',
			content: [
				<ClickableWithSubtitle {...USLinks.mainLinks.blog} />,
				<SmallLink {...USLinks.subLinks.blog4} />,
				<SmallLink {...USLinks.subLinks.blog5} />,
				<SmallLink {...USLinks.subLinks.blog3} />,
			],
		},
	],
}

const info = country => (isUSA(country) ? USInfo : otherInfo(country))

export default info
