// @flow
import React, { useState } from 'react'
import { Div } from 'glamorous'
import _ from 'lodash'
import { CSSTransition } from 'react-transition-group'

import { navy } from 'shared/colors'
import { tablet } from 'shared/media-queries'
import { mobileHeaderHeight } from '../index'
import CloseIcon from '../../../../imgs/icons/close.svg'
import ArrowIcon from '../../../../imgs/icons/carrot-arrow.svg'
import {
	transitionSpeed,
	transitionHiddenPos,
	transitionViewablePos,
} from '../index'
// import withCountry from 'shared/get-country'
import RootMenu from './root-menu'
import subMenus from './sub-menus'

const MobileMenu = ({
	isExpanded,
	toggleExpand,
	hideLogin,
	loginLink,
	hideCta,
	country,
}: {
	isExpanded: boolean,
	toggleExpand: () => null,
	hideLogin: boolean,
	loginLink: string,
	hideCta: boolean,
	country: string,
}) => {
	const [selectedMenuItem, updateMenuItem] = useState(null)
	const [currentSubMenu, setSubMenu] = useState(null)
	return (
		<>
			<Div
				background="#fff"
				width="100%"
				flexDirection="column"
				alignItems="stretch"
				textAlign="center"
				position="absolute"
				height="100vh"
				top="0"
				right="0"
				lineHeight={`${mobileHeaderHeight}px`}
				boxShadow="0 1px 2px rgba(0, 0, 0, 0.2)"
				fontWeight={500}
				display="none"
				css={{
					[tablet]: {
						display: isExpanded ? 'flex' : 'none',
					},
				}}
			>
				<Div
					display="flex"
					justifyContent="space-between"
					flex="0 0 50px"
					boxShadow="0 1px 1px rgba(0, 0, 0, 0.2)"
					paddingLeft="20px"
					paddingRight="20px"
				>
					<Div
						onClick={() => {
							updateMenuItem(null)
						}}
						display="flex"
						alignItems="center"
					>
						{selectedMenuItem && (
							<>
								<Div transform="rotate(-180deg)" marginBottom="5px">
									<ArrowIcon fill={navy} />
								</Div>
								<Div fontSize="18px" fontWeight="600" paddingLeft="10px">
									{selectedMenuItem}
								</Div>
							</>
						)}
					</Div>
					<Div
						marginTop="2px"
						onClick={() => {
							setSubMenu(null)
							updateMenuItem(null)
							toggleExpand()
						}}
					>
						<CloseIcon fill={navy} width="16px" height="16px" />
					</Div>
				</Div>
				<CSSTransition in={!!selectedMenuItem} timeout={500} classNames="slide">
					<Div
						width="100%"
						height="100vh"
						position="absolute"
						top="52px"
						bottom="0"
						right={transitionHiddenPos}
						onClick={e => {
							if (_.get(e, ['target', 'tagName']) === 'A') {
								setSubMenu(null)
								updateMenuItem(null)
								toggleExpand()
							}
						}}
						css={{
							'&.slide-enter': {
								transition: transitionSpeed,
								right: transitionViewablePos,
							},
							'&.slide-enter-active': {
								right: transitionViewablePos,
							},
							'&.slide-enter-done': {
								right: transitionViewablePos,
							},
							'&.slide-exit': {
								transition: transitionSpeed,
								right: transitionViewablePos,
							},
							'&.slide-exit-active': {
								right: transitionHiddenPos,
								transition: transitionSpeed,
							},
							'&.slide-exit-done': {
								right: transitionHiddenPos,
							},
						}}
					>
						{currentSubMenu && subMenus(country)[currentSubMenu]}
					</Div>
				</CSSTransition>
				<RootMenu
					hideLogin={hideLogin}
					loginLink={loginLink}
					hideCta={hideCta}
					setSubMenu={setSubMenu}
					updateMenuItem={updateMenuItem}
					country={country}
				/>
			</Div>
		</>
	)
}

export default MobileMenu
