import React from 'react'
import { Div } from 'glamorous'

import { getPx } from '../shared/media-queries'

const CenteredContent = ({
	children,
	maxWidth = 1280,
	overflow = 'hidden',
}) => {
	const actualMaxWidth =
		typeof maxWidth === 'string' ? getPx(maxWidth) : maxWidth

	return (
		<Div overflow={overflow}>
			<Div maxWidth={`${actualMaxWidth}px`} padding="0 20px" margin="0 auto">
				{children}
			</Div>
		</Div>
	)
}

export default CenteredContent
