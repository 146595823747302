// @flow
import * as React from 'react'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'

import BCLogo from 'imgs/bc-logo.svg'
import BCLogoNoText from 'imgs/bc-logo-notext.svg'

export const transitionSpeed = 200

const TransitionalDiv = styled.div`
	position: absolute;
	height: 100%;
	display: flex;
	align-items: center;
	&.fade-enter {
		opacity: 0;
	}
	&.fade-enter-active {
		opacity: 1;
		transition: opacity ${transitionSpeed}ms;
	}
	&.fade-exit {
		opacity: 1;
	}
	&.fade-exit-active {
		opacity: 0;
		transition: opacity ${transitionSpeed}ms;
	}
`

type Props = { noText: boolean, className: string }
const Logo = ({ noText, className }: Props) => (
	<>
		<CSSTransition
			in={!noText}
			classNames="fade"
			timeout={transitionSpeed}
			unmountOnExit
		>
			<TransitionalDiv>
				<BCLogo className={className} />
			</TransitionalDiv>
		</CSSTransition>
		<CSSTransition
			in={noText}
			classNames="fade"
			timeout={transitionSpeed}
			unmountOnExit
		>
			<TransitionalDiv>
				<BCLogoNoText />
			</TransitionalDiv>
		</CSSTransition>
	</>
)

export default Logo
