// @flow
import * as React from 'react'

import { Div } from 'glamorous'
import { laptop } from 'shared/media-queries'
import CenteredContent from '../../../centered-content'

import { ContentItem } from '../components'
import { white } from 'shared/colors'

const HeaderMenu = ({ content }: { content: Array<React.Node> }) => (
	<Div
		backgroundColor={white}
		maxWidth="1320px"
		width="100%"
		margin="0 auto"
		boxShadow="inset 0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 1px 0 0 rgba(0, 0, 0, 0.15)"
		borderRadius="0 0 2px 2px"
		css={{ [laptop]: { width: '100vw' } }}
	>
		<CenteredContent>
			<Div
				display="flex"
				justifyContent="space-around"
				height="auto"
				padding="25px 0"
			>
				{content.map((info, i) => (
					<React.Fragment key={i}>
						<ContentItem info={info} />
						{i < content.length - 1 && <Div flex="0 0 30px" />}
					</React.Fragment>
				))}
			</Div>
		</CenteredContent>
	</Div>
)

export default HeaderMenu
