export default function trackEvent({ category, action, label, value }) {
	if (window.ga) {
		window.ga('send', {
			hitType: 'event',
			eventCategory: category,
			eventAction: action,
			eventLabel: label,
			eventValue: value,
		})
	}
}
