// @flow
import * as React from 'react'
import { Div, A } from 'glamorous'

import NewBadge from 'icons/new-badge.svg'
import { mobile } from 'shared/media-queries'
import { cyan } from 'shared/colors'

const getDefaultBanner = ({
	wording,
	ctaWording = 'Check it out',
	href,
	noBadge,
}: {
	wording: string,
	ctaWording: string,
	href: string,
	noBadge?: boolean,
}) => ({ closeBanner }: { closeBanner: () => mixed }) => (
	<Div
		width="100%"
		display="flex"
		css={{
			[mobile]: { flexDirection: 'column' },
		}}
	>
		{!noBadge && <NewBadge display="block" />}
		<Div flex="0 0 12px" />
		<Div>
			{wording}{' '}
			{href && (
				<A color={cyan} onClick={closeBanner} href={href} target="_blank">
					{ctaWording} &raquo;
				</A>
			)}
		</Div>
		<Div flex="0 0 12px" />
	</Div>
)

export const preconVirtualEventBanner = {
	localStorageKey: 'preconVirtualEventBannerClosed',
	Content: getDefaultBanner({
		wording: `Don't miss out on our free virtual event: Winning with Digital Preconstruction.`,
		ctaWording: `Save your spot`,
		href: ` http://acsprograms.autodesk.com/winningwithdigitalpreconstruction?utm_medium=placement&utm_source=website&utm_campaign=acc-precon-winning-with-digital&utm_content=bc-hero-banner`,
	}),
}

export const bigRoomBanner = {
	localStorageKey: 'bigRoomBannerClosed',
	Content: getDefaultBanner({
		wording: `Join The Big Room, an online construction community designed for customers to connect, ideate, and grow with one another.`,
		ctaWording: `Check it out!`,
		href: `https://construction.autodesk.com/big-room`,
	}),
}
