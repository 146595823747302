// @flow
import * as React from 'react'
import { A, Div } from 'glamorous'
import { Link } from 'gatsby'
import { blue, grayText } from 'shared/colors'
import { tablet, mobile } from 'shared/media-queries'
import { isRelative } from 'shared/is-relative-url'

const ClickableWithSubtitle = ({
	title,
	subtitle,
	url,
	styles,
}: {
	title: string,
	subtitle: string,
	url: string,
	styles?: {},
}) => (
	<Div marginTop="15px" {...styles}>
		<Div
			fontSize="18px"
			fontWeight="600"
			color={blue}
			display="flex"
			cursor="pointer"
			css={{
				[tablet]: { marginBottom: '-20px', fontSize: '16px' },
				[mobile]: { fontSize: '14px' },
			}}
		>
			<Div
				css={{
					'&:hover': {
						textDecoration: 'underline',
					},
				}}
			>
				{isRelative(url) ? (
					<A href={url} color={blue}>
						{title} &raquo;
					</A>
				) : (
					<Link to={url} css={{ color: blue }}>
						{title} &raquo;
					</Link>
				)}
			</Div>
		</Div>
		<Div
			fontSize="14px"
			color={grayText}
			padding="5px 0 5px"
			css={{
				[tablet]: { display: 'none' },
			}}
		>
			{subtitle}
		</Div>
	</Div>
)

export default ClickableWithSubtitle
