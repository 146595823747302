// @flow
import * as React from 'react'
import { useState, useEffect } from 'react'
import { Div } from 'glamorous'

import { mobile, tablet } from 'shared/media-queries'
import CloseIcon from 'imgs/icons/close.svg'
import { desktopHeaderHeight, mobileHeaderHeight } from './header'

const Banner = ({
	banner: { localStorageKey, Content, customCloseButton },
}: {
	banner: {
		localStorageKey: string,
		Content: any,
		customCloseButton?: any,
	},
}) => {
	const [showBanner, setShowBanner] = useState(false)

	useEffect(() => {
		if (!window.localStorage.getItem(localStorageKey)) {
			setShowBanner(true)
		}
	}, [localStorageKey])

	const closeBanner = () => {
		setShowBanner(false)
		window.localStorage.setItem(localStorageKey, 'true')
	}

	return showBanner ? (
		<Div
			backgroundColor="#122749"
			height="80px"
			css={{
				marginTop: `${desktopHeaderHeight}px`,
				marginBottom: `-${desktopHeaderHeight}px`,
				[tablet]: {
					marginTop: `${mobileHeaderHeight}px`,
					marginBottom: `-${mobileHeaderHeight}px`,
				},
				[mobile]: {
					height: 'auto',
				},
			}}
		>
			{/* Matches the header which is wrapped in an old centered content component */}
			<Div height="100%" maxWidth="1280px" padding="0 20px" margin="0 auto">
				<Div
					color="white"
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					fontSize="16px"
					height="100%"
				>
					<Div
						width="100%"
						display="flex"
						alignItems="center"
						justifyContent="space-between"
					>
						<Content closeBanner={closeBanner} />
						{customCloseButton ? (
							<Div cursor="pointer" onClick={closeBanner}>
								{customCloseButton}
							</Div>
						) : (
							<Div
								cursor="pointer"
								css={{
									[mobile]: {
										position: 'absolute',
										top: '60px',
										right: '20px',
									},
								}}
								onClick={closeBanner}
							>
								<CloseIcon fill="#fff" width="12px" height="12px" />
							</Div>
						)}
					</Div>
				</Div>
			</Div>
		</Div>
	) : null
}

export default Banner
