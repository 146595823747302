// @flow
import * as React from 'react'
import { A, Div } from 'glamorous'
import { Link } from 'gatsby'
import { tablet, mobile } from 'shared/media-queries'
import { blue } from 'shared/colors'
import ArrowIcon from '../../../../imgs/icons/carrot-arrow.svg'

type Props = {
	title: string,
	urlRoot?: string,
	href?: string,
	to?: string,
	openMenu?: () => mixed,
	onClick?: () => mixed,
}

export const sharedDivCSS = {
	[tablet]: {
		height: '114px',
		justifyContent: 'center',
		textAlign: 'left',
		padding: '35px 17px',
	},
	[mobile]: {
		display: 'flex',
		flex: '0 0 75px',
		flexDirection: 'column',
		padding: '0px 20px',
	},
}

const sharedLinkCSS = {
	color: blue,
	height: '100%',
	width: '100,',
	display: 'block',
}

const MenuLink = ({ title, to, href }: Props) => {
	return (
		<Div css={sharedDivCSS}>
			<Div
				color={blue}
				fontsize="18px"
				fontWeight="600"
				css={{ [mobile]: { fontSize: '14px' } }}
			>
				{href ? (
					<A href={href} css={sharedLinkCSS}>
						{title}
					</A>
				) : (
					<Link to={to} css={sharedLinkCSS}>
						{title}
					</Link>
				)}
			</Div>
		</Div>
	)
}

const MenuLinkWithSubMenu = ({ title, openMenu }: Props) => {
	return (
		<Div css={sharedDivCSS}>
			<Div
				display="flex"
				justifyContent="space-between"
				color={blue}
				fontsize="18px"
				fontWeight="600"
				onClick={openMenu}
				css={{ [mobile]: { fontSize: '14px' } }}
			>
				{title}
				<Div>
					<ArrowIcon fill={blue} width="9.5px" height="15px" />
				</Div>
			</Div>
		</Div>
	)
}

export const MobileHeaderLink = (props: Props) => {
	return props.openMenu ? (
		<MenuLinkWithSubMenu {...props} />
	) : (
		<MenuLink {...props} />
	)
}
