import { contractorTypeByLocale } from 'shared/get-country'

export const subMenuTitles = country => ({
	products: 'Products',
	gcs: contractorTypeByLocale(country),
	subs: 'Subcontractors',
	resources: 'Resources',
	owners: 'Owners',
	network: 'Network',
	login: 'Login',
})
