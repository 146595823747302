// @flow
import * as React from 'react'
import { useState, useEffect } from 'react'
import { Div } from 'glamorous'
import _ from 'lodash'
import { connect } from 'react-redux'

import { laptop } from 'shared/media-queries'
import { headerLinkClassName, desktopHeaderHeight } from '../index'
import HeaderMenu from './index'
import { Info } from '../shared/info-type'
import { actions } from 'state/header'
import { headerUnderlinedCSS } from './shared'

type HeaderLinkProps = {
	title: string,
	content: Array<Info>,
	underlinedLink: string,
	setUnderlinedLink: string => mixed,
	defaultUnderlineRegex?: RegExp,
}

const HeaderLinkWithMenu = ({
	title,
	content,
	underlinedLink,
	setUnderlinedLink,
	defaultUnderlineRegex,
}: HeaderLinkProps) => {
	const [menuOpen, toggleMenu] = useState(false)

	// Underline one of the options as a default when loading the page directly
	useEffect(() => {
		const href = window.location.href
		if (
			!underlinedLink &&
			defaultUnderlineRegex &&
			defaultUnderlineRegex.test(href)
		) {
			setUnderlinedLink(title)
		}
	}, [defaultUnderlineRegex, setUnderlinedLink, title, underlinedLink])

	const additionalHeaderLinkCSS =
		underlinedLink === title ? headerUnderlinedCSS : {}

	return (
		<Div
			onMouseEnter={() => toggleMenu(true)}
			onMouseLeave={() => toggleMenu(false)}
			height={desktopHeaderHeight}
			display="flex"
			alignItems="center"
		>
			<Div
				className={headerLinkClassName}
				{...additionalHeaderLinkCSS}
				css={{
					cursor: 'default',
				}}
			>
				{title}
				{menuOpen && (
					<Div backgroundColor="transparent">
						<Div
							position="absolute"
							height="auto"
							borderRadius="0 0 2px 2px"
							paddingTop="20px"
							left="0"
							right="0"
							css={{ [laptop]: { left: 0, margin: 'auto' } }}
							onClick={e => {
								if (_.get(e, ['target', 'tagName']) === 'A') {
									toggleMenu(false)
									setUnderlinedLink(title)
								}
							}}
						>
							<HeaderMenu content={content} />
						</Div>
					</Div>
				)}
			</Div>
		</Div>
	)
}

export default connect(
	state => ({ underlinedLink: state.header.underlinedLink }),
	{ setUnderlinedLink: actions.setUnderlinedLink }
)(HeaderLinkWithMenu)
