import React from 'react'
import { A, Div, H3, Span } from 'glamorous'
import { Link } from 'gatsby'

import { showRequestDemoModal } from '../modal-manager'
import CenteredContent from '../centered-content'
import Spacer from '../spacer'
import { navy } from '../../shared/colors'
import {
	smallerThan,
	largerThan,
	mediaQueryBetween,
} from '../../shared/media-queries'
import Logo from '../../imgs/bc-logo.svg'
import FbIcon from '../../imgs/social/Facebook.svg'
import TwitterIcon from '../../imgs/social/Twitter.svg'
import LinkedInIcon from '../../imgs/social/LinkedIn.svg'
import InstagramIcon from '../../imgs/social/Insta.svg'
import generateContent from './footer-content'
import withCountry, { isUSA } from 'shared/get-country'

const Heading = ({ children }) => {
	return (
		<H3
			fontSize="15px"
			fontWeight={600}
			color={navy}
			margin={0}
			letterSpacing="1.2px"
		>
			{children}
		</H3>
	)
}

const Column = ({ children }) => {
	return (
		<Div
			display="flex"
			flex="1 0 0%"
			fontSize="18px"
			minWidth="0"
			flexDirection="column"
			css={{
				':not(:first-child)': { marginLeft: '32px' },
				'& > *': { ':not(:first-child)': { marginTop: '20px' } },
			}}
		>
			{children}
		</Div>
	)
}

const Footer = ({ country }) => {
	const content = generateContent(country)
	if (!country) return null
	const {
		supportEmail,
		helpCenterUrl,
		supportEmailCopy,
		gcs,
		subs,
		forSubsLink,
	} = content
	return (
		<CenteredContent>
			<Spacer height={50} />
			<Div
				display="flex"
				css={{
					'@media (max-width: 1000px)': { flexDirection: 'column' },
					'& a': {
						color: '#595959',
					},
				}}
			>
				<Div
					css={{
						[largerThan('small')]: {
							flex: '1 0 0%',
							marginRight: '32px',
							marginTop: '-8px',
						},
						[smallerThan('small')]: {
							flex: '0 0 auto',
							marginBottom: '40px',
						},
					}}
				>
					<Logo width="240px" />
				</Div>
				<Div
					display="flex"
					css={{
						[smallerThan('x-small')]: {
							flexDirection: 'column',
							flex: '0 0 auto',
						},
						[largerThan('x-small')]: {
							flex: '2 0 0%',
						},
					}}
				>
					<Div
						display="flex"
						css={{
							[smallerThan('x-small')]: {
								flex: '0 0 auto',
								marginBottom: '40px',
							},
							[largerThan('x-small')]: {
								flex: '1 0 0%',
								marginBottom: '32px',
							},
						}}
					>
						<Column>
							<Heading>OUR PRODUCT</Heading>
							<Link to="/bc-pro">{gcs}</Link>
							<Link to={forSubsLink}>{subs}</Link>
							{isUSA(country) && <Link to="/owners">For Owners</Link>}
						</Column>
						<Column>
							<Heading>COMPANY</Heading>
							{isUSA(country) && <a href="https://construction.autodesk.com/why-autodesk-construction-cloud/">
									About
								</a>}
							{isUSA(country) && (
								<a href="https://www.autodesk.com/careers/construction-solutions">
									Careers
								</a>
							)}
							<a href="https://constructionblog.autodesk.com/" target="_blank">
								Blog
							</a>
						</Column>
					</Div>
					<Div
						display="flex"
						css={{
							[smallerThan('x-small')]: {
								flex: '0 0 auto',
							},
							[largerThan('x-small')]: {
								flex: '1 0 0%',
							},
						}}
					>
						<Column>
							<Heading>GET IN TOUCH</Heading>
							<a href={helpCenterUrl}>Help Center</a>
							<a href="https://buildingconnected.zendesk.com/hc/en-us?solvvy=true">Contact Support</a>
							<A cursor="pointer" onClick={() => showRequestDemoModal()}>
								Request a Demo
							</A>
						</Column>
						<Column>
							<Heading>CONNECT</Heading>
							<Div display="flex" width="150px" justifyContent="space-between">
								<a href="https://www.facebook.com/BuildingConnectedcom-195490413862378">
									<FbIcon width="24px" height="24px" />
								</a>
								<a href="https://www.buildingconnected.com/">
									<LinkedInIcon width="24px" height="24px" />
								</a>
								<a href="https://twitter.com/BldgConnected">
									<TwitterIcon width="24px" height="24px" />
								</a>
								<a href="http://instagram.com/buildingconnected">
									<InstagramIcon width="24px" height="24px" />
								</a>
							</Div>
						</Column>
					</Div>
				</Div>
			</Div>
			<Spacer height={75} />
			<Div
				borderTop={`1px solid ${navy}`}
				fontWeight={300}
				display="flex"
				justifyContent="space-between"
				css={{
					[smallerThan('xx-small')]: {
						fontSize: '13px',
					},
					[largerThan('xx-small')]: {
						fontSize: '14px',
					},
				}}
			>
				<Div color="#595959" marginTop="14px">
					© BuildingConnected {new Date().getFullYear()}
				</Div>
				<Div
					marginTop="14px"
					display="flex"
					css={{
						'& a': { color: '#797979' },
						'& > *:not(:first-child)': {
							[smallerThan('xx-small')]: {
								marginLeft: '14px',
							},
							[mediaQueryBetween('xx-small', 'small')]: {
								marginLeft: '28px',
							},
							[largerThan('small')]: {
								marginLeft: '38px',
							},
						},
					}}
				>
					<a href="https://app.buildingconnected.com/docs/#introduction">
						<Span css={{ [smallerThan('x-small')]: { display: 'none' } }}>
							API Documentation
						</Span>
						<Span css={{ [largerThan('x-small')]: { display: 'none' } }}>
							API
						</Span>
					</a>
					<a href="https://www.autodesk.com/company/legal-notices-trademarks/ccpa-do-not-sell">
						<Span css={{ [smallerThan('x-small')]: { display: 'none' } }}>
							Do not sell or share my personal information
						</Span>
						<Span css={{ [largerThan('x-small')]: { display: 'none' } }}>
							Data
						</Span>
					</a>
					<Link to="/terms">
						<Span css={{ [smallerThan('x-small')]: { display: 'none' } }}>
							Terms of Service
						</Span>
						<Span css={{ [largerThan('x-small')]: { display: 'none' } }}>
							Terms
						</Span>
					</Link>
					<Link to="/privacy">
						<Span css={{ [smallerThan('x-small')]: { display: 'none' } }}>
							Privacy Policy
						</Span>
						<Span css={{ [largerThan('x-small')]: { display: 'none' } }}>
							Privacy
						</Span>
					</Link>
					{/* eslint-disable-next-line no-script-url */}
					<A data-opt-in-preferences href="javascript:;">
						Privacy settings
					</A>
					<A
						href={supportEmail}
						css={{ [smallerThan('xx-small')]: { display: 'none' } }}
					>
						{supportEmailCopy}
					</A>
				</Div>
			</Div>
			<Spacer height={25} />
		</CenteredContent>
	)
}

export default withCountry(Footer)
