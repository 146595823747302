// @flow
import * as React from 'react'
import { Div } from 'glamorous'
import { ContentItem } from '../components'

import { Info } from '../shared/info-type'

const MobileSubMenu = ({ info }: { info: Info }) => (
	<Div
		backgroundColor="white"
		position="absolute"
		left="0"
		height="100vh"
		width="100%"
		textAlign="left"
		paddingBottom="175px"
		overflow="scroll"
	>
		<Div paddingTop="10px">
			{info.map((content, key) => (
				<ContentItem info={content} key={key} />
			))}
		</Div>
	</Div>
)

export default MobileSubMenu
