// @flow
import * as React from 'react'
import sectionInfo from './section-info'

import MobileSubMenu from './sub-menu'
import { subMenuTitles } from './constants'

const menu = country => ({
	products: (
		<MobileSubMenu
			info={sectionInfo(country).products}
			menuItem={subMenuTitles(country).products}
		/>
	),
	gcs: (
		<MobileSubMenu
			info={sectionInfo(country).gcs}
			menuItem={subMenuTitles(country).gcs}
		/>
	),
	subs: (
		<MobileSubMenu
			info={sectionInfo(country).subs}
			menuItem={subMenuTitles(country).subs}
		/>
	),
	resources: (
		<MobileSubMenu
			info={sectionInfo(country).resources}
			menuItem={subMenuTitles(country).resources}
		/>
	),
})

export default menu
