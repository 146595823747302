import React from 'react'
import { Div } from 'glamorous'

import './index.css'
import './font.css'
import Footer from './footer'
import FooterLite from './footer-lite'
import ModalManager from '../modal-manager'
import { tablet } from 'shared/media-queries'
import Header, { desktopHeaderHeight, mobileHeaderHeight } from './header'
import Banner from './banner'
import { bigRoomBanner } from 'shared/banners'
import SEOContent from './seo-content'

const Layout = ({
	children,
	title,
	description,
	hideNav,
	actuallyHideNav,
	useLiteFooter,
	headerCta,
	HeaderElements,
	hideCta,
	hideLogin,
	hideFooter,
	banner = bigRoomBanner,
}) => {
	const footer = useLiteFooter ? <FooterLite /> : <Footer />
	const marginTopHack = actuallyHideNav ? 0 : desktopHeaderHeight
	const marginTopMobileHack = actuallyHideNav ? 0 : mobileHeaderHeight
	return (
		<div>
			<SEOContent title={title} description={description} />
			{!actuallyHideNav && (
				<>
					<Header
						hideNav={hideNav}
						headerCta={headerCta}
						HeaderElements={HeaderElements}
						hideCta={hideCta}
						hideLogin={hideLogin}
					/>
					{banner ? <Banner banner={banner} /> : null}
				</>
			)}
			<Div
				marginTop={`${marginTopHack}px`}
				css={{
					[tablet]: {
						marginTop: `${marginTopMobileHack}px`,
					},
				}}
				overflow="hidden"
			>
				{children}
			</Div>
			{hideFooter ? null : footer}
			<ModalManager />
		</div>
	)
}

export default Layout
