import React from 'react'
import { Button, Div } from 'glamorous'
import { CSSTransition } from 'react-transition-group'

import { smallerThan } from 'shared/media-queries'
import { showRequestDemoModal } from '../../modal-manager'
import trackEvent from 'shared/track'
import { blue, white } from 'shared/colors'
import HeaderLinkWithMenu from './header-menu/menu-header-link'
import { HeaderCta } from './index'
import HeaderLink from './header-menu/header-link'
import sectionInfo from './header-menu/section-info'
import withCountry, { contractorTypeByLocale, isUSA } from 'shared/get-country'

export const transitionSpeed = '200ms'

const DemoButton = () => (
	<Button
		color={blue}
		display="block"
		height="44px"
		lineHeight="44px"
		borderRadius="2px"
		border={`1px solid ${blue}`}
		background="transparent"
		padding="0 20px"
		fontSize="18px"
		cursor="pointer"
		onClick={() => {
			trackEvent({
				category: 'Request Demo',
				action: 'Open modal',
				label: 'Nav bar',
			})
			showRequestDemoModal()
		}}
		css={{
			[smallerThan(1170)]: {
				fontSize: '14px',
				height: '30px',
				lineHeight: '30px',
			},
			'&:hover': {
				color: white,
				backgroundColor: blue,
			},
		}}
	>
		Get a Demo
	</Button>
)

const buttonTransition = {
	'&.fade-enter': {
		marginRight: '-138px',
	},
	'&.fade-enter-active': {
		marginRight: '0',
		transition: `all ${transitionSpeed}`,
	},
	'&.fade-exit': {
		marginRight: '0',
	},
	'&.fade-exit-active': {
		marginRight: '-138px',
		transition: `all ${transitionSpeed}`,
	},
}

const NavBarLinks = ({
	hideLogin,
	headerCta,
	loginLink,
	showDemoButton,
	country,
}) => {
	const info = sectionInfo(country)
	const { products, gcs, subs, resources } = info
	return (
		<Div
			alignItems="center"
			display="flex"
			fontWeight={500}
			fontSize="18px"
			css={{
				'& > *:not(:first-child)': { marginLeft: '30px' },
				[smallerThan(1170)]: {
					fontSize: '14px',
				},
			}}
			overflow="hidden"
		>
			<HeaderLinkWithMenu content={products} title="Products" />
			<HeaderLink
				to="/customers/"
				title="Network"
				defaultUnderlineRegex={/customers\//gi}
			/>
			<HeaderLinkWithMenu
				content={gcs}
				title={contractorTypeByLocale(country)}
				defaultUnderlineRegex={/bc\-pro\/|tradetapp\//gi}
			/>
			<HeaderLinkWithMenu
				urlRoot="bid-board"
				content={subs}
				title="Subcontractors"
				defaultUnderlineRegex={/bid\-board\//gi}
			/>
			{isUSA(country) && (
				<HeaderLink
					to="/owners/"
					title="Owners"
					defaultUnderlineRegex={/owners\//gi}
				/>
			)}
			<HeaderLinkWithMenu
				content={resources}
				title="Resources"
				defaultUnderlineRegex={/videos\//gi}
			/>
			{!hideLogin && loginLink}
			{headerCta && (
				<Div>
					<HeaderCta text={headerCta.text} href={headerCta.href} />
				</Div>
			)}
			<CSSTransition
				in={showDemoButton}
				classNames="fade"
				timeout={200}
				unmountOnExit
			>
				<Div css={buttonTransition}>
					<DemoButton />
				</Div>
			</CSSTransition>
		</Div>
	)
}

export default withCountry(NavBarLinks)
