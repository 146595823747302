// @flow
import * as React from 'react'
import { Div } from 'glamorous'
import { grayText, navy } from 'shared/colors'
import { tablet, mobile } from 'shared/media-queries'

import { ThinDivider } from '../components'

import { Info } from '../shared/info-type'

const ContentItem = ({
	info: { title, content, flex = '1 1 0%' },
}: {
	info: Info,
}) => (
	<Div flex={flex}>
		{title && (
			<Div css={{ [tablet]: { marginLeft: '20px' } }}>
				<Div
					fontSize="14px"
					color={grayText}
					letterSpacing="1px"
					css={{
						[tablet]: {
							color: navy,
							marginBottom: '-12px',
						},
						[mobile]: { fontSize: '12px' },
					}}
				>
					{title.toUpperCase()}
				</Div>
				<ThinDivider wide />
			</Div>
		)}
		<Div display="flex" flexDirection="column">
			{content.map((item, key) => (
				<Div key={key} css={{ [tablet]: { marginLeft: '20px' } }}>
					{item}
				</Div>
			))}
		</Div>
	</Div>
)

export default ContentItem
