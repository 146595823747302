// @flow
import * as React from 'react'
import { Div } from 'glamorous'
import { ClickableWithSubtitle, SmallLink } from '../components'
import { isUSA } from 'shared/get-country'
import { USLinks, otherLinks } from '../shared/links-with-copy'

const otherInfo = country => ({
	products: [
		{
			title: 'Tender Management',
			content: [
				<ClickableWithSubtitle {...otherLinks(country).mainLinks.bcpro} />,
				<SmallLink {...otherLinks(country).subLinks.subsNetwork} />,
				<SmallLink {...otherLinks(country).subLinks.bidAnalyticsBC} />,
				<SmallLink {...otherLinks(country).subLinks.bidInvite} />,
				<SmallLink {...otherLinks(country).subLinks.bidLeveling} />,
				<SmallLink {...otherLinks(country).subLinks.bcproWistiaVideos} />,
			],
		},
		{
			title: 'Opportunity Tracking',
			content: [
				<ClickableWithSubtitle {...otherLinks(country).mainLinks.tenders} />,
				<SmallLink {...otherLinks(country).subLinks.tenderingToolkit} />,
			],
		},
	],
	gcs: [
		{
			title: 'Tender Management',
			content: [
				<ClickableWithSubtitle {...otherLinks(country).mainLinks.bcpro} />,
				<SmallLink {...otherLinks(country).subLinks.subsNetwork} />,
				<SmallLink {...otherLinks(country).subLinks.bidAnalyticsBC} />,
				<SmallLink {...otherLinks(country).subLinks.bcproWistiaVideos} />,
				<ClickableWithSubtitle
					{...otherLinks(country).mainLinks.subNetworkOther}
				/>,
				<ClickableWithSubtitle
					{...otherLinks(country).mainLinks.tenderComparison}
				/>,
			],
		},
	],
	subs: [
		{
			title: 'Opportunity Tracking',
			content: [
				<ClickableWithSubtitle
					{...otherLinks(country).mainLinks.getDiscoveredHead}
				/>,
			],
		},
		{
			title: 'Getting Started',
			content: [
				<ClickableWithSubtitle
					{...otherLinks(country).mainLinks.getStartedTendering}
				/>,
			],
		},
	],
	resources: [
		{
			title: '',
			content: [
				<ClickableWithSubtitle {...otherLinks(country).mainLinks.blog} />,
				<Div width="90%" display="flex" flexDirection="column" lineHeight="1.4">
					<SmallLink {...otherLinks(country).subLinks.blog4} />
					<SmallLink {...otherLinks(country).subLinks.blog5} />
					<SmallLink {...otherLinks(country).subLinks.blog3} />
				</Div>,
			],
		},
		{
			title: '',
			content: [
				<Div
					display="flex"
					justifyContent="space-between"
					flexDirection="column"
					marginTop="30px"
				>
					<ClickableWithSubtitle
						{...otherLinks(country).mainLinks.helpCenter}
					/>
					<ClickableWithSubtitle
						{...otherLinks(country).mainLinks.customerStories}
					/>
				</Div>,
			],
		},
	],
})

const USInfo = {
	products: [
		{
			title: 'Bid Management',
			content: [
				<ClickableWithSubtitle {...USLinks.mainLinks.bcpro} />,
				<SmallLink {...USLinks.subLinks.subsNetwork} />,
				<SmallLink {...USLinks.subLinks.bidAnalyticsBC} />,
				<SmallLink {...USLinks.subLinks.riskMitigationTT} />,
				<SmallLink {...USLinks.subLinks.bidInvite} />,
				<SmallLink {...USLinks.subLinks.bidLeveling} />,
				<SmallLink {...USLinks.subLinks.bcproPlansPricing} />,
				<SmallLink {...USLinks.subLinks.bcproROICalc} />,
				<SmallLink {...USLinks.subLinks.bcproWistiaVideos} />,
			],
		},
		{
			title: 'Risk Management',
			content: [
				<ClickableWithSubtitle {...USLinks.mainLinks.tradetapp} />,
				<SmallLink {...USLinks.subLinks.riskAnalysis} />,
				<SmallLink {...USLinks.subLinks.riskMitigation} />,
				<SmallLink {...USLinks.subLinks.vendorManagement} />,
				<SmallLink {...USLinks.subLinks.bcTradetapp} />,
				<SmallLink {...USLinks.subLinks.tradetappWistiaVideos} />,
				<SmallLink {...USLinks.subLinks.tradetappRoiCalc} />,
			],
		},
		{
			title: 'Opportunity Tracking',
			content: [
				<ClickableWithSubtitle {...USLinks.mainLinks.bbpro} />,
				<SmallLink {...USLinks.subLinks.bidTracking} />,
				<SmallLink {...USLinks.subLinks.bidCalendar} />,
				<SmallLink {...USLinks.subLinks.bidAnalyticsITB} />,
				<SmallLink {...USLinks.subLinks.takeoffs} />,
				<SmallLink {...USLinks.subLinks.plansPricing} />,
				<SmallLink {...USLinks.subLinks.bbWistiaVideos} />,
			],
		},
	],
	gcs: [
		{
			title: 'Bid Management',
			content: [
				<ClickableWithSubtitle {...USLinks.mainLinks.bcpro} />,
				<SmallLink {...USLinks.subLinks.subsNetwork} />,
				<SmallLink {...USLinks.subLinks.bidAnalyticsBC} />,
				<SmallLink {...USLinks.subLinks.riskMitigationTT} />,
				<SmallLink {...USLinks.subLinks.bidInvite} />,
				<SmallLink {...USLinks.subLinks.bidLeveling} />,
				<SmallLink {...USLinks.subLinks.bcproPlansPricing} />,
				<SmallLink {...USLinks.subLinks.bcproROICalc} />,
				<SmallLink {...USLinks.subLinks.bcproWistiaVideos} />,
			],
		},
		{
			title: 'Risk Management',
			content: [
				<ClickableWithSubtitle {...USLinks.mainLinks.tradetapp} />,
				<SmallLink {...USLinks.subLinks.riskAnalysis} />,
				<SmallLink {...USLinks.subLinks.riskMitigation} />,
				<SmallLink {...USLinks.subLinks.vendorManagement} />,
				<SmallLink {...USLinks.subLinks.bcTradetapp} />,
				<SmallLink {...USLinks.subLinks.tradetappWistiaVideos} />,
			],
		},
	],
	subs: [
		{
			title: 'Opportunity Tracking',
			content: [
				<ClickableWithSubtitle {...USLinks.mainLinks.bbpro} />,
				<SmallLink {...USLinks.subLinks.bidTracking} />,
				<SmallLink {...USLinks.subLinks.bidCalendar} />,
				<SmallLink {...USLinks.subLinks.bidAnalyticsITB} />,
				<SmallLink {...USLinks.subLinks.takeoffs} />,
				<SmallLink {...USLinks.subLinks.plansPricing} />,
				<SmallLink {...USLinks.subLinks.bbWistiaVideos} />,
			],
		},
		{
			title: 'How to Win the Bids You Want',
			flex: '1 1 20%',
			content: [
				<Div
					display="flex"
					flexDirection="column"
					justifyContent="space-between"
				>
					<ClickableWithSubtitle {...USLinks.mainLinks.useNetwork} />
					<ClickableWithSubtitle {...USLinks.mainLinks.getStarted} />
					<ClickableWithSubtitle {...USLinks.mainLinks.movebbo} />
					<ClickableWithSubtitle {...USLinks.mainLinks.winMore} />
				</Div>,
			],
		},
	],
	resources: [
		{
			title: '',
			content: [
				<ClickableWithSubtitle {...USLinks.mainLinks.blog} />,
				<Div width="90%" display="flex" flexDirection="column" lineHeight="1.4">
					<SmallLink {...USLinks.subLinks.blog4} />
					<SmallLink {...USLinks.subLinks.blog5} />
					<SmallLink {...USLinks.subLinks.blog3} />
				</Div>,
			],
		},
		{
			title: '',
			content: [
				<Div
					display="flex"
					justifyContent="space-between"
					flexDirection="column"
					marginTop="30px"
				>
					<ClickableWithSubtitle {...USLinks.mainLinks.helpCenter} />
					<ClickableWithSubtitle {...USLinks.mainLinks.customerStories} />
				</Div>,
			],
		},
	],
}

const info = country => (isUSA(country) ? USInfo : otherInfo(country))

export default info
