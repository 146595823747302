// @flow
import { useState, useEffect } from 'react'

import isBrowser from 'shared/is-browser'

const useScreenWidth = () => {
	const initialWidth = isBrowser ? window.innerWidth : null
	const [width, setWidth] = useState(initialWidth)

	useEffect(() => {
		const handleResize = () => setWidth(window.innerWidth)

		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	return width
}

export default useScreenWidth
