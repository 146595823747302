// @flow
import * as React from 'react'
import { useEffect } from 'react'
import { Link } from 'gatsby'
import { A } from 'glamorous'
import { connect } from 'react-redux'

import { headerLinkClassName } from '../index'
import { actions } from 'state/header'
import { headerUnderlinedCSS } from './shared'

const HeaderLink = ({
	to,
	href,
	className = headerLinkClassName,
	underlinedLink,
	setUnderlinedLink,
	title,
	defaultUnderlineRegex,
}: {
	to?: string,
	href?: string,
	className: string,
	underlinedLink: string,
	setUnderlinedLink: string => mixed,
	title: string,
	defaultUnderlineRegex: RegExp,
}) => {
	const additionalCSS = underlinedLink === title ? headerUnderlinedCSS : {}

	// Underline one of the options as a default when loading the page directly
	useEffect(() => {
		const currentHref = window.location.href
		if (
			!underlinedLink &&
			defaultUnderlineRegex &&
			defaultUnderlineRegex.test(currentHref)
		) {
			setUnderlinedLink(title)
		}
	}, [defaultUnderlineRegex, setUnderlinedLink, title, underlinedLink])

	return href ? (
		<A
			className={className}
			href={href}
			onClick={() => setUnderlinedLink(title)}
			{...additionalCSS}
		>
			{title}
		</A>
	) : (
		<Link
			to={to}
			className={className}
			onClick={() => setUnderlinedLink(title)}
			css={additionalCSS}
		>
			{title}
		</Link>
	)
}

export default connect(
	state => ({ underlinedLink: state.header.underlinedLink }),
	{ setUnderlinedLink: actions.setUnderlinedLink }
)(HeaderLink)
