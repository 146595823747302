// @flow
import * as React from 'react'
import { Div } from 'glamorous'
import { gray20 } from 'shared/colors'
import { laptop } from 'shared/media-queries'

const ThinDivider = ({ wide }: { wide?: boolean }) => {
	return wide ? (
		<Div
			height="1px"
			backgroundColor={gray20}
			width="100%"
			css={{ [laptop]: { width: '95%' } }}
		/>
	) : (
		<Div
			width="1px"
			height="157px"
			backgroundColor={gray20}
			css={{ [laptop]: { display: 'none' } }}
		/>
	)
}

export default ThinDivider
