import React from 'react'
import Helmet from 'react-helmet'

import normalize from 'shared/normalize-text'
import useCountry from 'hooks/use-country'

const langCodeMap = {
	AU: 'en-AU',
	NZ: 'en-NZ',
	US: 'en-US',
	UK: 'en-GB',
	IE: 'en-IE',
}

const hrefMap = {
	AU: 'com.au',
	NZ: 'co.nz',
	US: 'com',
	UK: 'co.uk',
	IE: 'ie',
}

const SEOContent = ({ title, description }) => {
	const country = useCountry()
	const lang = langCodeMap[country]

	return (
		<Helmet
			title={title}
			meta={[
				{
					name: 'description',
					content: normalize(description) || 'BuildingConnected',
				},
			]}
		>
			<html lang={lang} />
			<link
				rel="alternate"
				href={`https://www.buildingconnected.${hrefMap[country]}/`}
				hrefLang={lang}
			/>
		</Helmet>
	)
}

export default SEOContent
