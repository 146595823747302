import React from 'react'
import _ from 'lodash'

export default Component => {
	return class WithScreenWidth extends React.Component {
		state = {
			screenWidth: (typeof window !== 'undefined' && window.innerWidth) || 1920,
		}

		render() {
			return <Component screenWidth={this.state.screenWidth} {...this.props} />
		}

		componentDidMount() {
			window.addEventListener('resize', this.handleResize)
		}

		componentWillUnmount() {
			window.removeEventListener('resize', this.handleResize)
		}

		handleResize = _.debounce(e => {
			this.setState({ screenWidth: e.target.innerWidth })
		}, 250)
	}
}
