// @flow
import * as React from 'react'
import { A, Div } from 'glamorous'
import { Link } from 'gatsby'
import { blue } from 'shared/colors'
import { tablet, mobile } from 'shared/media-queries'
import { isRelative } from 'shared/is-relative-url'

const sharedStyles = {
	height: '100%',
	width: '100%',
	'&:hover': {
		textDecoration: 'underline',
	},
}
const linkCss = {
	...sharedStyles,
	color: blue,
	[mobile]: {
		padding: '5px 0 5px',
	},
}

const SmallLink = ({ title, url }: { title: string, url: string }) => (
	<Div
		css={{
			fontSize: '14px',
			fontWeight: '300',
			marginTop: '10px',
			[tablet]: {
				fontSize: '16px',
				paddingLeft: '20px',
				marginTop: isRelative(url) ? '20px' : '0px',
			},
			[mobile]: {
				fontSize: '14px',
			},
		}}
	>
		{isRelative(url) ? (
			<A href={url} color={blue} css={sharedStyles}>
				{title}
			</A>
		) : (
			<Link cursor="pointer" padding="10px 0 10px" to={url} css={linkCss}>
				{title}
			</Link>
		)}
	</Div>
)

export default SmallLink
