import React from 'react'
import ReactDOM from 'react-dom'
import { Link } from 'gatsby'
import { A, Div } from 'glamorous'
import { css } from 'glamor'
import { CSSTransition } from 'react-transition-group'
import _ from 'lodash'
import withCountry from 'shared/get-country'
import '../index.css'
import Logo from './logo'
import CenteredContent from '../../centered-content'
import { blue, navy } from 'shared/colors'
import {
	largerThan,
	smallerThan,
	tablet,
	mobile,
	breakpoints,
} from 'shared/media-queries'
import HamburgerIcon from '../../../imgs/icons/hamburger.svg'
import NavBarLinks from './navbar-links'
import MobileMenu from './mobile-menu'
import useScreenWidth from 'hooks/use-screen-width'

export const desktopHeaderHeight = 60
export const mobileHeaderHeight = 50
export const transitionSpeed = '250ms'
export const transitionHiddenPos = '-100%'
export const transitionViewablePos = '0%'

export const headerLinkClassName = css({
	color: navy,
	lineHeight: '20px',
	'&:hover': {
		color: blue,
		borderBottom: `2px solid ${blue}`,
		marginBottom: '-2px',
	},
})

const Underlay = ({ isExpanded, toggleExpand }) => (
	<Div
		width="100vw"
		height="100vh"
		top="0"
		backgroundColor="rgba(0, 0, 0, 0.5)"
		position="fixed"
		display="none"
		css={{ [tablet]: { display: isExpanded ? 'inherit' : 'none' } }}
		onClick={toggleExpand}
	/>
)

const loginLink = (
	<A
		href="https://app.buildingconnected.com"
		css={{
			color: blue,
			'&:hover, &:active, &:visited': { color: blue },
			[smallerThan(1170)]: {
				fontSize: '14px',
				height: '20px',
			},
		}}
	>
		Login
	</A>
)

export const HeaderCta = headerCta => {
	return (
		<A
			color={blue}
			display="block"
			height="44px"
			lineHeight="44px"
			borderRadius="2px"
			border={`1px solid ${blue}`}
			background="transparent"
			padding="0 20px"
			fontSize="18px"
			cursor="pointer"
			fontWeight={500}
			href={headerCta.href}
			css={{ [smallerThan('xx-small')]: { display: 'none' } }}
		>
			{headerCta.text}
		</A>
	)
}

const Header = ({
	isExpanded,
	toggleExpand,
	hideNav,
	headerCta,
	HeaderElements,
	hideCta,
	hideLogin,
	headerRef,
	scrolledPastHero,
	country,
}) => {
	const screenWidth = useScreenWidth()
	// Shortens the logo by removing the text to make room for a demo button on the right
	// Should only happen for screen sizes laptop and above and shouldn't happen for the mobile menu
	const showModifiedHeader =
		scrolledPastHero && screenWidth >= breakpoints.laptop

	return (
		<Div
			ref={headerRef}
			background="#fff"
			boxShadow="0 1px 2px rgba(0, 0, 0, 0.2)"
			position="fixed"
			top={0}
			right={0}
			left={0}
			zIndex="10"
		>
			<CenteredContent overflow={'visible'}>
				<Div
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					height={`${desktopHeaderHeight}px`}
					css={{
						[tablet]: {
							height: `${mobileHeaderHeight}px`,
						},
					}}
				>
					<Link
						to="/"
						style={{
							textDecoration: 'none',
						}}
						css={{
							height: `${desktopHeaderHeight}px`,
							[tablet]: {
								height: `${mobileHeaderHeight}px`,
							},
						}}
					>
						<Logo
							noText={showModifiedHeader}
							display="block"
							css={{
								marginTop: '-5px',
								[largerThan('tablet')]: {
									width: '247px',
								},
								[smallerThan(1170)]: {
									width: '200px',
								},
							}}
						/>
					</Link>
					{!hideNav && !isExpanded && (
						<Div
							onClick={toggleExpand}
							cursor="pointer"
							display="block"
							height="50px"
							width="50px"
							zIndex="1"
							marginRight="-20px"
							lineHeight={isExpanded ? '3' : '2.75'}
							textAlign="center"
							display="none"
							css={{
								[tablet]: {
									display: 'block',
								},
							}}
						>
							<HamburgerIcon fill="#000" width="16px" height="12px" />
						</Div>
					)}
					{!hideNav && (
						<Div
							zIndex="1" // Fix for logo container blocking this from view in IE
							css={{
								[tablet]: {
									display: 'none',
								},
							}}
						>
							<NavBarLinks
								hideLogin={hideLogin}
								hideCta={hideCta}
								headerCta={headerCta}
								loginLink={loginLink}
								showDemoButton={showModifiedHeader}
							/>
						</Div>
					)}
					{HeaderElements && (
						<Div
							display="flex"
							fontWeight={500}
							fontSize="18px"
							css={{
								[smallerThan('x-small')]: {
									display: 'none',
								},
							}}
						>
							<HeaderElements />
						</Div>
					)}
				</Div>
			</CenteredContent>
			{!hideNav && (
				<>
					<Underlay isExpanded={isExpanded} toggleExpand={toggleExpand} />
					<CSSTransition in={isExpanded} timeout={500} classNames="slide">
						<Div
							width="100%"
							maxWidth="375px"
							height="100vh"
							position="absolute"
							top="0"
							bottom="0"
							right={transitionHiddenPos}
							css={{
								[mobile]: { width: '90%' },
								'&.slide-enter': {
									transition: transitionSpeed,
									right: transitionViewablePos,
								},
								'&.slide-enter-active': {
									right: transitionViewablePos,
								},
								'&.slide-enter-done': {
									right: transitionViewablePos,
								},
								'&.slide-exit': {
									transition: transitionSpeed,
									right: transitionViewablePos,
								},
								'&.slide-exit-active': {
									right: transitionHiddenPos,
									transition: transitionSpeed,
								},
								'&.slide-exit-done': {
									right: transitionHiddenPos,
								},
							}}
						>
							<MobileMenu
								hideLogin={hideLogin}
								hideCta={hideCta}
								loginLink={'https://app.buildingconnected.com'}
								isExpanded
								toggleExpand={toggleExpand}
								country={country}
							/>
						</Div>
					</CSSTransition>
				</>
			)}
		</Div>
	)
}

class HeaderContainer extends React.Component {
	constructor() {
		super()

		this.state = { isExpanded: false, scrolledPastHero: false }
		this.handleClick = this.handleClick.bind(this)
	}

	render() {
		const { isExpanded, scrolledPastHero } = this.state
		return (
			<Header
				{...this.props}
				isExpanded={isExpanded}
				scrolledPastHero={scrolledPastHero}
				toggleExpand={() => {
					if (document && document.body) {
						document.body.style.overflow = isExpanded ? 'visible' : 'hidden'
					}
					this.setState(state => ({ isExpanded: !state.isExpanded }))
				}}
				headerRef={this.headerRef}
			/>
		)
	}

	componentDidMount() {
		document.body.addEventListener('click', this.handleClick)
		window.addEventListener('scroll', this.handleScroll)
	}

	componentWillUnmount() {
		document.body.removeEventListener('click', this.handleClick)
		window.removeEventListener('scroll', this.handleScroll)
	}

	headerRef = React.createRef()

	handleClick(e) {
		const node = ReactDOM.findDOMNode(this.headerRef.current)

		if (node && !node.contains(e.target)) {
			this.setState({ isExpanded: false })
		}
	}

	handleScroll = _.throttle(() => {
		// ensure all browsers are covered
		const value =
			window.pageYOffset ||
			document.documentElement.scrollTop ||
			document.body.scrollTop ||
			0

		// About the amount scrolled it looked best to show the modified header
		if (value <= 500) {
			this.setState({ scrolledPastHero: false })
		} else {
			this.setState({ scrolledPastHero: true })
		}
	}, 200)
}

export default withCountry(HeaderContainer)
